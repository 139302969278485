<template>
  <div class="d-flex justify-center mb-3">
    <div class="summary-info">
      <p class="text-center mt-0 time">
        {{ formatHour(workdays.drive_total) }}
      </p>
      <p class="text-center mb-0 summary">
        {{ $t('components.lists.mirrors.summary.drive_time_total') }}
      </p>
    </div>
    <div class="summary-info">
      <p class="text-center mt-0 time">
        {{ formatHour(workdays.available_total) }}
      </p>
      <p class="text-center mb-0 summary">
        {{ $t('components.lists.mirrors.summary.available_time_total') }}
      </p>
    </div>
    <div class="summary-info">
      <p class="text-center mt-0 time">
        {{ formatHour(workdays.wait_total) }}
      </p>
      <p class="text-center mb-0 summary">
        {{ $t('components.lists.mirrors.summary.wait_time_total') }}
      </p>
    </div>
    <div class="summary-info">
      <p class="text-center mt-0 time">
        {{ formatHour(workdays.half_extra_hour_total) }}
      </p>
      <p class="text-center mb-0 summary">
        {{ $t('components.lists.mirrors.summary.partial_receivings') }}
      </p>
    </div>
    <div class="summary-info">
      <p class="text-center mt-0 time">
        {{ formatHour(workdays.full_extra_hour_total) }}
      </p>
      <p class="text-center mb-0 summary">
        {{ $t('components.lists.mirrors.summary.full_receivings') }}
      </p>
    </div>
    <div class="summary-info">
      <p class="text-center mt-0 time">
        {{ formatHour(workdays.night_increase_total) }}
      </p>
      <p class="text-center mb-0 summary">
        {{ $t('components.lists.mirrors.summary.night_increase') }}
      </p>
    </div>
    <div class="summary-info">
      <p class="text-center mt-0 time">
        {{ formatHour(workdays.intra_total) }}
      </p>
      <p class="text-center mb-0 summary">
        {{ $t('components.lists.mirrors.summary.intra_workday') }}
      </p>
    </div>
    <div class="summary-info">
      <p class="text-center mt-0 time">
        {{ formatHour(workdays.overnight_total) }}
      </p>
      <p class="text-center mb-0 summary">
        {{ $t('components.lists.mirrors.summary.inter_workday') }}
      </p>
    </div>
    <!-- <div class="summary-info">
      <p class="text-center mt-0 time">
        {{ formatHour(workdays.pay_drive_total) }}
      </p>
      <p class="text-center mb-0 summary">
        {{ $t('components.lists.mirrors.summary.pay_drive_time_total') }}
      </p>
    </div> -->
    <div class="summary-info">
      <p class="text-center mt-0 time">
        {{ formatHour(workdays.pay_wait_total) }}
      </p>
      <p class="text-center mb-0 summary">
        {{ $t('components.lists.mirrors.summary.pay_wait_time_total') }}
      </p>
    </div>

    <div class="summary-info">
      <p class="text-center mt-0 time">
        {{ formatHour(workdays.dsr_suppressed_total) }}
      </p>
      <p class="text-center mb-0 summary">
        {{ $t('components.lists.mirrors.summary.dsr_suppressed_total') }}
      </p>
    </div>

    <div class="summary-info">
      <p class="text-center mt-0 time">
        {{ workdays.quantity_dsr_suppressed }}
      </p>
      <p class="text-center mb-0 summary">
        {{ $t('components.lists.mirrors.summary.quantity_dsr_suppressed') }}
      </p>
    </div>
  </div>
</template>

<script>
import formats from '@/mixins/formats';

export default {
  name: 'PointMirrorSummary',
  mixins: [formats],
  props: {
    workdays: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/lists/point_mirror.scss" scoped>
