<template>
  <v-row
    no-gutters
    justify="center"
  >
    <v-dialog
      v-model="dialogOpen"
      max-width="310px"
      :change="closeModal()"
    >
      <v-card>
        <v-card-title class="mb-6 d-flex justify-center">
          <span>
            {{ $t('components.dialogs.new_workday.title') }}
          </span>
        </v-card-title>

        <ValidationObserver v-slot="{ valid }">
          <v-card-text class="d-flex justify-center">
            <ValidationProvider rules="required">
              <v-date-picker
                v-model="date"
                no-title
                :allowed-dates="disabledAfterToday"
              />
            </validationprovider>
          </v-card-text>

          <v-card-text class="d-flex justify-center">
            <v-btn
              color="error"
              @click="cancelAction()"
            >
              {{ $t('components.dialogs.new_workday.cancel') }}
            </v-btn>
            <v-btn
              class="ml-6"
              color="accent"
              outlined
              :disabled="!valid"
              @click="confirmAction()"
            >
              {{ $t('components.dialogs.new_workday.confirm') }}
            </v-btn>
          </v-card-text>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'NewWorkdayDialog',
  model: {
    prop: 'dialog',
    event: 'change',
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogOpen: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    };
  },
  watch: {
    dialog() {
      this.dialogOpen = this.dialog;
    },
  },
  methods: {
    disabledAfterToday(date) {
      const today = new Date();

      today.setHours(0, 0, 0, 0);

      return new Date(date) < today || new Date(date) > new Date(today.getTime() + 7 * 24 * 3600 * 1000);
    },

    closeModal() {
      this.$emit('change', this.dialogOpen);
    },

    cancelAction() {
      this.dialogOpen = false;

      this.kind = null;
      this.adjustmentReason = null;

      this.$emit('change', this.dialogOpen);
    },

    confirmAction() {
      this.$emit('newWorkday', this.date);
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/dialogs/drivers.scss" scoped />
