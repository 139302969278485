<template>
  <v-container
    class="mirror-header"
  >
    <v-row
      class="content pa-0"
      justify="space-between"
    >
      <v-col
        class="px-2 d-flex"
        cols="3"
        sm="3"
      >
        <div class="user">
          <span class="name">{{ userName }}</span>
          <br>
          <span class="document">{{ userCpf }}</span>
          <br>
          <span class="document">{{ userPhone }}</span>
        </div>
      </v-col>
      <v-col
        class="d-flex justify-end pt-4"
        cols="18"
      >
        <v-btn
          color="accent"
          @click="newWorkday()"
        >
          {{ text('new_workday') }}
        </v-btn>
      </v-col>
      <v-col
        class="px-2 d-flex"
        cols="3"
        sm="3"
      >
        <date-range @datesSelected="applyFilter" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DateRange from '@/components/forms/calendar/DateRange.vue';
import formats from '@/mixins/formats';

export default {
  name: 'MirrorShowHeader',
  components: {
    DateRange,
  },
  mixins: [formats],
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    userId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    term: '',
    dates: [],
  }),
  computed: {
    userName() {
      if (this.user && this.user.name) {
        return this.user.name;
      }
      return '';
    },
    userCpf() {
      if (this.user && this.user.cpf) {
        return this.user.cpf;
      }
      return '';
    },
    userPhone() {
      if (this.user && this.user.phone) {
        return this.user.phone;
      }
      return '';
    },
  },
  methods: {
    text(label) {
      return this.$t(`components.header.mirrors.${label}`);
    },
    applyFilter(dates) {
      this.$store.dispatch('initLoading');
      this.$emit('fetchMirrorsByDate', {
        id: this.userId,
        date_init: dates[0],
        date_end: dates[1],
      });
    },
    newWorkday() {
      this.$emit('newWorkday');
    },
  },
};
</script>

<style lang="scss" scoped>
.mirror-header {
  padding: 12px 0;

  .content {
    .v-text-field--outlined.v-input--dense .v-label {
      top: 12px;
    }

    .user {
      .name {
        font-size: 20px;
        font-family: 'Acumin-Pro-Bold';
      }
      .document {
        font-size: 14px;
        font-family: 'Acumin-Pro-Regular';
        color: $color_subtitle;
      }
    }

    .search {
      width: 246px;
      height: 42px;

      .v-label {
        padding-top: 1px;
      }
    }

    .button {
      font-family: 'Fira-Sans-Bold';
      letter-spacing: 0;
      height: 42px;
      width: 220px;
    }
  }
}
</style>
