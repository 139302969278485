<template>
  <div class="point-mirror">
    <mirror-show-header
      :user-id="userId"
      :user="userData"
      @fetchMirrorsByDate="fetchMirrorsByDate"
      @newWorkday="openDialog"
    />
    <point-mirror-summary
      v-if="hasWorkdays"
      :workdays="getWorkdays"
    />
    <mirror-show-list
      fixed-header
      :show-buttons="true"
      :workdays="workdays"
      @showWorkdayDetails="showWorkdayDetails"
    />

    <new-workday-dialog
      v-model="dialog"
      @newWorkday="newWorkday"
    />
  </div>
</template>

<script>
import formats from '@/mixins/formats';
import { mapGetters, mapActions } from 'vuex';
import MirrorShowHeader from '@/components/headers/MirrorShowHeader.vue';
import MirrorShowList from '@/components/lists/MirrorShowList.vue';
import PointMirrorSummary from '@/components/details/PointMirrorSummary.vue';
import NewWorkdayDialog from '@/components/dialogs/NewWorkdayDialog.vue';

export default {
  name: 'PointMirrorShow',
  components: {
    MirrorShowHeader,
    MirrorShowList,
    PointMirrorSummary,
    NewWorkdayDialog,
  },
  mixins: [formats],
  data: () => ({
    userId: 0,
    context: 'mirrorList',
    dialog: false,
  }),
  computed: {
    ...mapGetters([
      'getWorkdays',
      'getDateRange',
    ]),
    userData() {
      if (this.getWorkdays) {
        return {
          name: this.getWorkdays.name,
          cpf: this.getWorkdays.cpf,
          phone: this.getWorkdays.phone,
        };
      }
      return {
        name: '',
        cpf: '',
        phone: '',
      };
    },
    workdays() {
      if (this.getWorkdays) {
        return this.getWorkdays.workdays;
      }
      return [];
    },
    hasWorkdays() {
      return this.getWorkdays && this.getWorkdays.workdays && !!this.getWorkdays.workdays.length;
    },
  },
  created() {
    if (this.$route.query.date_init !== undefined) {
      this.saveDateRange([this.$route.query.date_init, this.$route.query.date_end]);
      this.saveDateFatherComponent(this.context);
    }

    this.userId = Number(this.$route.params.id);

    this.initLoading();
    if (this.getDateRange.length > 0) {
      this.fetchMirrorsByStore(this.getDateRange);
    } else {
      this.fetchMirrorsByStore(this.currentMonth());
    }
    this.initCurrentBreadcrumbs(
      [
        {
          text: this.$i18n.t('breadcrumbs.point_mirror'),
          disabled: false,
          exact: true,
          to: {
            name: 'mirrorList',
          },
        },
        {
          text: this.$i18n.t('breadcrumbs.driver'),
          disabled: true,
        },
      ],
    );
  },
  methods: {
    ...mapActions([
      'fetchWorkdaysByUser',
      'initCurrentBreadcrumbs',
      'saveDateRange',
      'saveDateFatherComponent',
      'initLoading',
      'createNewWorkday',
    ]),
    fetchMirrorsByStore(dates) {
      this.fetchMirrorsByDate({
        id: this.userId,
        date_init: dates[0],
        date_end: dates[1],
      });
    },
    fetchMirrorsByDate(params) {
      this.fetchWorkdaysByUser(params);
    },
    showWorkdayDetails(workdayId) {
      const routeData = this.$router.resolve({
        name: 'workdayShow',
        params: {
          workdayId,
          userId: this.userId,
        },
      });
      window.open(routeData.href, '_blank');
    },
    openDialog() {
      this.dialog = true;
    },

    newWorkday(date) {
      this.createNewWorkday({ user_id: this.userId, date });
    },

  },
};
</script>
